<template>
    <div class="card-tm bg">
        <section class="launchpad-token p-2 border-top">
            <div class="container-fluid">
                <div class="row justify-content-center">
                  
                    <div class="col-md-12 col-lg-8 col-xl-8 order-2 order-lg-1">
                        <div class="token_box mb-3 mb-xl-0">
                            <slot></slot>

                        </div>
                    </div>
                    <div class="col-md-12 col-lg-3 col-xl-3 order-1 order-lg-2">
                        <div class="token_box card border-0 shadow-sm mb-3 ">
                            <IeoComponent :roundData="roundData" :status="status"
                                v-on:update:roundData="roundData = $event" />
                        </div>
                        <div class="token_box card border-0 shadow-sm mb-3">
                            <InvestComponent :listData="response" :tokenData="tokenData" :roundData="roundData"
                                v-on:update:tokenData="tokenData = $event" v-on:update:roundData="roundData = $event" />
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import InvestComponent from '../components/launchpad/InvestComponent.vue'
import IeoComponent from '../components/launchpad/IeoComponent.vue'
export default {
    name: 'TokenLayout',
    props: {
        listData: Object,
        tokenData: Object
    },
    components: {
        InvestComponent,
        IeoComponent
    },
    data() {
        return {
            response: {},
            roundData: {},
            status: 'ongoing',
        }

    },
    watch: {
        listData: function (v) {
            this.response = v;
        },
        tokenData: function (v) {
            console.log('middle child ===', v);
            this.tokenData = v;
            this.$emit("update:tokenData", v);
            this.status = v.status;
            this.$emit("update:roundData", v.launchpad_rounds[0]);
            this.roundData = v.launchpad_rounds[0];

        }
    },
}
</script>

<style scoped>
/* custome container */
.cus_container {
    max-width: 1650px;
    /* max-width: 85%; */
}

</style>
